module.exports = {
  // General
  title: `Studio WillemsPeeters`,
  description: `Studio WillemsPeeters is a design studio founded by Henk Willems and Jelena Peeters.`,
  url: `https://willemspeeters.com`,
  language: `EN`,
  image: `social.png`,
  analytics: `UA-124422891-1`,

  // Colours
  themeColor: `#0000f9`,
  tileColor: `#ffffff`,

  // Twitter
  twitterHandle: ``, // @someone
  twitterCard: `summary_large_image`, // `summary`, `summary_large_image`, `app`

  //Facebook
  fbAppID: ``,
}
