import React, { useEffect, useState } from 'react'

const Scroller = props => {
  const [scroll, setScroll] = useState(0)
  const handleScroll = () => setScroll(window.pageYOffset)
  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  })
  const buttons = []
  for (var i = 0; i < props.buttons; i++) {
    buttons.push(
      <div key={i}>
        <button
          className={props.active ? `menu-button active` : `menu-button`}
          onClick={props.onClick}
        >
          <span>{props.active ? `Close` : props.children[i % 2]}</span>
        </button>
      </div>
    )
  }
  return (
    <div
      id={props.position}
      className="scroll"
      style={{
        transform: `translate(${
          props.position === `top` ? `` : `-`
        }${scroll}px,0)`,
      }}
    >
      {buttons}
    </div>
  )
}

export default Scroller
