import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import Navigation from './Navigation'
import 'normalize.css'
import './layout.scss'
import Meta from './Meta'
import Footer from './Footer'

const Layout = ({ children, data }) => (
  <StaticQuery
    query={graphql`
      query SiteMetaDataQuery {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `}
    render={data => (
      <>
        <Meta />
        <Navigation />
        <main>{children}</main>
        <Footer />
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
