import React from 'react'
import { Link } from 'gatsby'
import Scroller from './Scroller'

class Navigation extends React.Component {
  constructor() {
    super()
    this.state = {
      active: false,
      names: [`willems`, `peeters`],
    }
    this.toggleActive = () => {
      this.setState(prevState => ({
        active: prevState.active ? false : true,
      }))
    }
  }

  componentDidMount() {
    document.body.style.overflow = this.state.active ? `hidden` : `visible`
  }

  componentDidUpdate() {
    document.body.style.overflow = this.state.active ? `hidden` : `visible`
  }

  render() {
    return (
      <nav>
        <Scroller
          position="top"
          active={this.state.active}
          onClick={this.toggleActive}
          buttons={50}
        >
          {this.state.names}
        </Scroller>
        <Scroller
          position="bottom"
          active={this.state.active}
          onClick={this.toggleActive}
          buttons={50}
        >
          {this.state.names}
        </Scroller>
        <ul className={this.state.active ? `active` : ``}>
          <li>
            <Link activeClassName="active" to="/work">
              Work
            </Link>
          </li>
          <li>
            <Link activeClassName="active" to="/news">
              News
            </Link>
          </li>
          <li>
            <Link activeClassName="active" to="/about">
              About
            </Link>
          </li>
        </ul>
      </nav>
    )
  }
}

export default Navigation
