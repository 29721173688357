import React from 'react'
import { Link } from 'gatsby'
import './layout.scss'

const Footer = () => (
  <footer>
    <div className="container">
      <div className="columns">
        <div>
          <h2>Studio</h2>
          <ul>
            <li>
              <Link to="/work">Work</Link>
            </li>
            <li>
              <Link to="/news">News</Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
          </ul>
        </div>
        <div>
          <h2>Connect</h2>
          <ul>
            <li>
              <a href="https://www.instagram.com/willemspeeters/">Instagram</a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/willemspeeters/">
                LinkedIn
              </a>
            </li>
          </ul>
        </div>
        <div>
          <h2>Contact</h2>
          <p>
            For new business, contact us at{' '}
            <a href="mailto:info@willemspeeters.com">info@willemspeeters.com</a>
          </p>
          <p>
            Or say hi personally at{' '}
            <a href="mailto:henk@willemspeeters.com">henk@willemspeeters.com</a>{' '}
            and{' '}
            <a href="mailto:jelena@willemspeeters.com">
              jelena@willemspeeters.com
            </a>
          </p>
        </div>
      </div>
      <div className="colophon">
        <span>
          Website by <a href="https://lettera.co/">Lettera</a>
        </span>
      </div>
    </div>
  </footer>
)

export default Footer
