import React from 'react'
import Helmet from 'react-helmet'
import urlJoin from 'url-join'
import global from '../config/site-config'

const Meta = function(props) {
  // Check if props.meta exist
  const page = typeof props.data !== 'undefined' ? props.data : {}

  // Resolve data
  const title = page.title || global.title
  const description = page.description || global.description
  const image = page.image || urlJoin(global.url, global.image)
  const url = page.pathName ? urlJoin(global.url, page.pathName) : global.url
  const locale = page.locale || global.locale
  const twitterCard = page.twitterCard || global.twitterCard || `summary`

  return (
    <Helmet>
      {/* General */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="application-name" content={global.title} />
      <meta name="apple-mobile-web-app-title" content={global.title} />
      <meta name="theme-color" content={global.themeColor} />
      <meta name="msapplication-TileColor" content={global.tileColor} />

      {/* Icons */}
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="48x48"
        href="/favicon-48x48.png"
      />

      {/* OpenGraph */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={url} />
      <meta property="og:image" content={image} />
      <meta property="og:locale" content={locale} />
      {global.fbAppID ? (
        <meta property="fb:app_id" content={global.fbAppID} />
      ) : null}

      {/* Twitter */}
      <meta name="twitter:card" content={twitterCard} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta property="twitter:url" content={url} />
      <meta name="twitter:image" content={image} />
      {global.twitterHandle ? (
        <meta name="twitter:site" content={global.twitterHandle} />
      ) : null}
    </Helmet>
  )
}
export default Meta
